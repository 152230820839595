import React from 'react'

const Seccion_SoloImg= ({id, title, phg1,img1,imgAlt}) => {
  return (
    <div id={id} className='flex flex-col h-full w-full gap-12 py-10'>
        <h3>{title}</h3>
        <div className='flex flex-col gap-10 w-full h-full '>
          <p>{phg1}</p>
          <img className='w-4/5 h-4/5 self-center aspect-[4/3] object-contain' src={img1} alt={"Maria_Estevez_"+title} 
 />
        </div>
    </div>
  )
}

export default Seccion_SoloImg