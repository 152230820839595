import React from 'react'

const Seccion_ImgDoble = ({id, title, phg1, phg2, img1, img2, img3, imgAlt}) => {
  return (
    <div id={id} className='flex flex-col h-full w-full gap-12 py-10'>
        <h3>{title}</h3>
        <div className='flex flex-col lg:flex-row gap-10 lg:gap-20 w-full h-full '>
                <div className='flex flex-col order-2 lg:order-1 w-full lg:w-1/2 h-full  gap-10 lg:gap-20 justify-between'>
                    <img className='w-full h-full aspect-[4/3] object-cover' src={img1} alt={imgAlt+"_1"} />
                </div>

                <div className='flex flex-col order-1 lg:order-2 w-full lg:w-1/2 gap-10 lg:gap-20 justify-between '>
                    <p>{phg1}</p>
                    <img className='w-full h-full aspec-[4/3] object-cover' src={img2} alt={imgAlt+"_2"} />
                   
                </div>
        </div>
    </div>
  )
}

export default Seccion_ImgDoble