import React from "react";

import LinkMenu from "./linkMenu";
import { Link } from "react-router-dom";

const Portada_Interna =({links, img, imgAlt, title, page})=>{
  

    return(
        <div className="relative z-0 w-full h-full flex flex-col ">
           <div className={page === "escritos" || "fotografia" ? "relative flex flex-col gap-4 justify-center p-8 md:p-12 lg:p-28 h-[642px] w-full  bg-gradient-to-b from-primary-500/0   to-primary-500/100": "relative flex flex-col gap-4 justify-center p-8 md:p-12 lg:p-28 h-[642px] w-full"}>
             
                <img className={
                page==="escritos" ? "w-full h-full  blur-0 2xl:blur-[0.5px]  object-container object-left aspect-[4/3] lg:scale-y-[100%] absolute left-0 -z-10" : 
                page==="audiovisuales" ? "w-full h-full  blur-0 2xl:blur-[0.5px] object-cover md:object-contain  bg-black aspect-[4/3] lg:scale-y-[100%] absolute left-0 -z-10" :"w-full h-full  blur-0 2xl:blur-[0.5px] object-cover bg-black aspect-[4/3] lg:scale-y-[100%] absolute left-0 -z-10"}src={img} alt={"Maria_Estevez_"+title}  />
                <div className="flex flex-col gap-2  md:max-w-lg lg:max-w-full">
                    <h1 className="relative z-10 text-white opacity-50">{title}</h1>

                    <ul className="flex flex-wrap flex-shrink-0 w-full  flex-col lg:flex-row gap-2 lg:gap-8 uppercase text-white opacity-50 sub-menu ">
                        {links.map((link) => {
                            return (
                            <li key={link.name}>
                            <a href={link.href}>{link.name}<div></div></a>
                            </li>
                            )
                        })}
                    </ul>
                </div>
           </div>

          
        </div>
    )
}

export default Portada_Interna