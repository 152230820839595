import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "../NavBar";

const Layout=({isHome})=>{
return(
    <div>
      {isHome ? null : <Navbar />}
      <Outlet/>
    </div>
);
};

export default Layout;