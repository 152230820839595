import React from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

const Seccion_Masonry = ({id, title, images, phg1, phg2, img1, img2, img3, imgAlt}) => {
  return (
    <div id={id} className='flex flex-col h-full w-full gap-12 py-10'>
        <h3>{title}</h3>
        {/* <div className='flex flex-col lg:flex-row gap-10 lg:gap-20 w-full h-full '>
                <div className='flex flex-col order-2 lg:order-1 w-full lg:w-1/2 h-full  gap-10 lg:gap-20 justify-between'>
                    <img className='w-full h-full aspect-[4/3] object-cover' src={img1} alt={imgAlt+"_1"} />
                    <img className='w-full h-full aspect-[4/3] object-cover' src={img2} alt={imgAlt+"_2"} />
                </div>

                <div className='flex flex-col order-1 lg:order-2 w-full lg:w-1/2 gap-10 lg:gap-20 justify-between '>
                    <p>{phg1}</p>
                    <img className='w-full h-full aspec-[4/3] object-cover' src={img3} alt={imgAlt+"_3"} />
                    <p>{phg2}</p>
                </div>
        </div> */}

        <ResponsiveMasonry 
                columnsCountBreakPoints={{350: 1, 750: 1, 1024:2, 1280: 2}}
            >
                <Masonry gutter="20px">
                <p>{phg1}</p>
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={"Maria_Estevez_"+title+"_"+index} 

                  />
                ))}
                 <p>{phg2}</p>
                </Masonry>
            </ResponsiveMasonry>

    </div>
  )
}

export default Seccion_Masonry