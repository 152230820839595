import React from 'react'
import Portada from '../components/portada'

import Seccion_Video from '../components/seccion_video'

import Seccion_SoloImg from '../components/seccion_soloimg'
import Seccion_ImgSimple from '../components/seccion_imgsimple'

import Portada_Interna from '../components/portada_interna'
import Navbar from '../NavBar'
import Footer from '../components/footer'
import ButtonUp from '../components/buttonUp'
import Gallery from '../components/gallery'

export default function Fotografias() {
  const linksFotografias = [
  {
  name:"Black and White", 
  href:"#black_white"
  },
  {
  name:"Analogic", 
  href:"#analogic"
  },
  {
  name:"Composición a distancia", 
  href:"#composicion_distancia"
  },
  {
  name:"Composición", 
  href:"#composicion"
  },
  {
    name:"Ediciones Especiales",
    href:"#ediciones_especiales"
  },
  {
    name:"PH Gerardo Nuñez",
    href:"#gerardo_nunez"
  },

  {
    name:"Casa Donata",
    href:"#casa_donata"
  },
  {
    name:"Espacio el margen",
    href:"#espacio_elmargen"
  },

  {
  name:"PH Matías Lausada", 
  href:"#matias_lausada"
  },
  {
    name:"PH Alejandro Jandry", 
    href:"#alejandro_jandry"
    },
 
]

const imgages_BlackWhite = [
   "/png/fotografias/Black and White/foto.jpg",
   "/png/fotografias/Black and White/foto2.jpg",
   "/png/fotografias/Black and White/foto3.jpg",
   "/png/fotografias/Black and White/foto4.jpg",
   "/png/fotografias/Black and White/foto5.jpg"
   
]
const images_Analogic = [
  "/png/fotografias/Analogic PH Joaquin Mora/1.jpg",
  "/png/fotografias/Analogic PH Joaquin Mora/2.jpg",
  "/png/fotografias/Analogic PH Joaquin Mora/3.jpg",
  "/png/fotografias/Analogic PH Joaquin Mora/4.jpg",
  "/png/fotografias/Analogic PH Joaquin Mora/5.jpg",
  "/png/fotografias/Analogic PH Joaquin Mora/6.jpg",
  "/png/fotografias/Analogic PH Joaquin Mora/7.jpg",
  "/png/fotografias/Analogic PH Joaquin Mora/8.jpg",
  "/png/fotografias/Analogic PH Joaquin Mora/9.jpg",
]
const images_composicionDistancia = [
  "/png/fotografias/Composicion a Distancia PH Rafael Mesa España/2.jpg",
  "/png/fotografias/Composicion a Distancia PH Rafael Mesa España/3.jpg",
]
const images_composicion = [
  "/png/fotografias/Composicion PH Rodrigo Valero Puertas Mexico/1.jpg",
  "/png/fotografias/Composicion PH Rodrigo Valero Puertas Mexico/2.jpg",
  "/png/fotografias/Composicion PH Rodrigo Valero Puertas Mexico/3.jpg",
  "/png/fotografias/Composicion PH Rodrigo Valero Puertas Mexico/4.jpg",
]
const images_edicionesEspeciales = [
  "/png/fotografias/Ediciones Especiales/1.jpg",
  "/png/fotografias/Ediciones Especiales/2.jpg",
  "/png/fotografias/Ediciones Especiales/3.jpg",
  "/png/fotografias/Ediciones Especiales/4.jpg",
]
const images_gerardoNunez = [
  "/png/fotografias/PH Gerado Nuñez/1.jpg",
  "/png/fotografias/PH Gerado Nuñez/2.jpg",

]
const images_casaDonata = [
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Casa Donata/1.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Casa Donata/2.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Casa Donata/3.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Casa Donata/4.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Casa Donata/5.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Casa Donata/6.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Casa Donata/7.jpg",

]
const images_espacioElMargen = [
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/1.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/2.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/3.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/4.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/5.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/6.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/7.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/8.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/9.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/10.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/11.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/12.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/13.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/14.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/15.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/16.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/17.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/18.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/19.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/20.jpg",
  "/png/fotografias/PH MARTIN VOORTHEES Locacion Espacio el margen/21.jpg",

]
const images_matiasLausada = [
  "/png/fotografias/PH Matias Lausada/1.jpg",
  "/png/fotografias/PH Matias Lausada/2.jpg",
  "/png/fotografias/PH Matias Lausada/3.jpg",  
  "/png/fotografias/PH Matias Lausada/4.jpg",
]
const images_Alejandro = [
  "/png/fotografias/PH Alejandro Jandry/1.jpg",
  "/png/fotografias/PH Alejandro Jandry/2.jpg",
  "/png/fotografias/PH Alejandro Jandry/3.jpg", 
  "/png/fotografias/PH Alejandro Jandry/4.jpg",
  "/png/fotografias/PH Alejandro Jandry/5.jpg",
  "/png/fotografias/PH Alejandro Jandry/6.jpg"

]

  return (  
    <div className='flex flex-col w-full h-full  '>
       <div className=" z-10 ">
         <Navbar />
       </div>

    <Portada_Interna
    links={linksFotografias}
    title={"Producciones Fotográficas"}
    page={"fotografia"}
    img={"/png/portada-fotografias.jpg"}
    />

     <ButtonUp/>
      <div className='flex flex-col bg-primary-500 text-secondary-500 gap-32  px-8 lg:px-28 pt-10  pb-20'>
      <Gallery
        title={"Black and White - PH Jorge Argon"}
        id={"black_white"}
        images={imgages_BlackWhite}
      />

      <Gallery
        id={"analogic"}
        title={"Analogic - PH Joaquin Mora"}
        images={images_Analogic}
      />

        <Gallery
        id={"composicion_distancia"}
        title={"Composicion a Distancia - PH Rafael Mesa España"}
        images={images_composicionDistancia}
      />

      <Gallery
        id={"composicion"}
        title={"Composicion - PH Rodrigo Valero Puertas Mexico"}
        images={images_composicion}
      />

      <Gallery
        id={"ediciones_especiales"}
        title={"Ediciones Especiales"}
        images={images_edicionesEspeciales}
      />

      <Gallery
        id={"gerardo_nunez"}
        title={"PH Gerado Nuñez"}
        images={images_gerardoNunez}
      />
       <Gallery
        id={"casa_donata"}
        title={"Locacion Casa Donata - PH MARTIN VOORTHEES"}
        images={images_casaDonata}
      />
       <Gallery
        id={"espacio_elmargen"}
        title={" Locacion Espacio el margen - PH MARTIN VOORTHEES"}
        images={images_espacioElMargen}
      />

      <Gallery
        id={"matias_lausada"}
        title={"PH Matias Lausada"}
        images={images_matiasLausada}
      />
       <Gallery
        id={"alejandro_jandry"}
        title={"PH Alejandro Jandry"}
        images={images_Alejandro}
      />
      </div>
      <Footer/>
    </div>
  )
}
