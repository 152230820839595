import React, { useEffect, useState } from 'react'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import ReactPlayer from 'react-player'

export default function GalleryVideo({id, ph, title, images ,imgAlt, videos}) {
   
    return (
     
        <div id={id} className='flex flex-col'>
          <h3>{title} </h3>
          <ResponsiveMasonry 
                columnsCountBreakPoints={{350: 1, 750: 1, 1024:2, 1280: 3}}
            >
            

                <Masonry gutter="20px">
                       <div className=' relative w-full h-full bg-black '>
                          <ReactPlayer  className='aspect-video w-full h-full' width='100%' height='100%'
                           controls url={'/png/acrobacia/acrobacia_1.mp4'}/>
                        </div>

                        <div className=' relative w-full h-full bg-black'>
                          <ReactPlayer  className='aspect-video w-full h-full' width='100%' height='100%'
                            controls url={'/png/acrobacia/acrobacia_2.mp4'}/>
                        </div>

                        <div className=' relative w-full h-full  bg-black'>
                          <ReactPlayer  className='aspect-video w-full h-full' width='100%' height='100%'
                            controls url={'/png/acrobacia/acrobacia_3.mp4'}/>
                        </div>

                    
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={"Maria_Estevez_"+title+"_"+index} 

                  />
                ))}
                </Masonry>
            </ResponsiveMasonry>
        </div>

      );
    };