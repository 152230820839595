import React from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

const Seccion_Video= ({id, title, phg1, phg2, video, images}) => {
  return (
    <div id={id} className='flex flex-col h-full w-full gap-12 py-10'>
        <h3>{title}</h3>
       
          
          <ResponsiveMasonry 
                columnsCountBreakPoints={{350: 1, 750: 1, 1024:2, 1280: 2}}
            >
                <Masonry gutter="20px">
                <p>{phg1}</p> 
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={image.alt} 
                  />
                ))}
                 
                <div className='relative w-full h-full'>
                <iframe className='w-full h-full aspect-video' width="560" height="315" src={video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p>{phg2}</p>

                </Masonry>
            </ResponsiveMasonry>
        
    </div>
  )
}

export default Seccion_Video