import React from 'react'
import Portada from '../components/portada'

import Seccion_Video from '../components/seccion_video'

import Seccion_SoloImg from '../components/seccion_soloimg'
import Seccion_ImgSimple from '../components/seccion_imgsimple'
import Seccion_Masonry from '../components/seccion_masonry'
import Portada_Interna from '../components/portada_interna'
import Navbar from '../NavBar'
import Footer from '../components/footer'
import ButtonUp from '../components/buttonUp'

export default function Teatro() {
  const linksTeatro = [
    {
    name:"El hábito de mi voz", 
    href:"#el_habito"
    },
    {
      name:"Circo Mitológico", 
      href:"#circo_mitologico"
      },
    {
    name:"Purga", 
    href:"#purga"
    },
    {
    name:"Fausto", 
    href:"#fausto"
    },
    {
    name:"Inevitable Encuentro", 
    href:"#inevitable_encuentro"
    },
    {
    name:"Mulleres", 
    href:"#mulleres"
    }, 
    {
    name:"TACEC", 
    href:"#tacec"
    },
    {
    name:"Pecadora", 
    href:"#pecadora"
    },

    {
    name:"Teatro San Martín", 
    href:"#san_martin"
    },
    {
    name:"Casa Curutchet", 
    href:"#casa_curutchet"
    }, 


    {
    name:"La inquietud", 
    href:"#la_inquietud"
    },

]

const images_casaCurutchet = [
  "/png/teatro-danza/Casa Curutchet/4.jpg",
  "/png/teatro-danza/Casa Curutchet/3.jpeg",
 "/png/teatro-danza/Casa Curutchet/2.jpg",
 "/png/teatro-danza/Casa Curutchet/1.jpg",
]

const images_inevitableEncuentro = [
  "/png/teatro-danza/Inevitable Encuentro/1.jpg",
  "/png/teatro-danza/Inevitable Encuentro/2.jpg",
  "/png/teatro-danza/Inevitable Encuentro/3.jpg",
  "/png/teatro-danza/Inevitable Encuentro/4.jpg"
]

const images_fausto=[
"/png/teatro-danza/Fausto/1.webp",
"/png/teatro-danza/Fausto/2.jpg",
"/png/teatro-danza/Fausto/3.jpg"
]

const images_mulleres=[
  "/png/teatro-danza/Mulleres/1.jpg",
  "/png/teatro-danza/Mulleres/2.jpg",
  "/png/teatro-danza/Mulleres/3.jpg",
  "/png/teatro-danza/Mulleres/4.jpg"
]

const images_pecadora = [
  "/png/teatro-danza/Pecadora/1.jpg",
  "/png/teatro-danza/Pecadora/2.jpg",
  "/png/teatro-danza/Pecadora/3.jpg",
]

const images_purga = [
  "/png/teatro-danza/purga/2.jpg"
]
const images_elhabito = [
"/png/teatro-danza/El habito de mi voz/1.jpg",
"/png/teatro-danza/El habito de mi voz/3.jpg",
"/png/teatro-danza/El habito de mi voz/2.jpg"
]

const images_tatec = [
"/png/teatro-danza/TACEC/2.jpg",
"/png/teatro-danza/TACEC/3.jpg",
"/png/teatro-danza/TACEC/1.jpg"
]

const images_sanMartin = [
"/png/teatro-danza/San Martin/1.jpg",
"/png/teatro-danza/San Martin/2.jpg",
"/png/teatro-danza/San Martin/3.jpg",
"/png/teatro-danza/San Martin/4.jpg", 
]

const images_circoMitologico = [
  "/png/teatro-danza/Circo Mitologico/1.jpg",
  "/png/teatro-danza/Circo Mitologico/2.jpg",
  "/png/teatro-danza/Circo Mitologico/3.jpg",
 
  ]
  return (  
    <div className='flex flex-col w-full h-full '>
       <div className=" z-10 ">
         <Navbar />
       </div>
      <Portada_Interna
      links={linksTeatro}
      title={"Teatro"}
      img={"/png/portada-teatro.jpg"}
      />

     <ButtonUp/>
      <div className='flex flex-col bg-primary-500 text-secondary-500 gap-32  px-8 lg:px-28 pt-10 '>
        <Seccion_Masonry
        id={"el_habito"}
        title={"El hábito de mi voz"}
        phg1={"Una casa donde una mujer y sus múltiples facetas deambulan en la búsqueda de un presente posible. Un recorrido/encuentro sensorial hacia nuestra esencia escénica.¿En que se convierte la materia cuando ya no está habitada?."}
        phg2={"FICHA TÉCNICO ARTÍSTICA Texto: Rosario Alfaro Actúan:Daniela Camezzana, Pamela Esquivel, María Estévez, Alejandra Ferreyra Ortíz, Aurelia Osorio, Julieta Ranno Intérpretes voz:Rosario Alfaro, Annika Willemsen Vestuario: Magali Salvatore Iluminación: Martín Galle."}
        images={images_elhabito}
        />
          <Seccion_Video
        id={"circo_mitologico"}
        title={"Circo Mitológico"}
        phg1={"CIRCO MITOLOGICO  Gisela Banzer Curaduría y Produccion Jassia Protto  DEPURAMADRE ESPACIO DE ARTE. Puesta en escena e Inauguración Performática."}
        phg2={"La obra central ZOO1907, es un homenaje a aquellos animales que no pudieron vivir en libertad o sus años en cautiverio, no les permitió regresar a su hábitat. A todas esas especies exóticas como leones, jirafas, hipopótamos, rinocerontes, tigres, elefantes, ciervos, y tantos otros que dejaron sus tierras, cruzaron el océano y desde el 16 de octubre de 1907, día de la inauguración del Jardín Zoológico de La Plata, formaron parte de la infancia de muchos, a pesar de sí mismos. "}
        images={images_circoMitologico}
        video={"https://www.youtube.com/embed/8O3hE9Pj4gU"}
        />

        <Seccion_Video
        id={"purga"}
        title={"Purga"}
        phg1={"Purga es una producción audiovisual  del Centro de Arte de la UNLP que surgió a partir de la puesta en escena de un trabajo creado por María Estevez, en el espacio creativo Tête à Tête que dirige Claudio Hochman. "}
        phg2={"Tres artistas participaron en la ejecución musical: Fernanda tocó los violines, Martin la percusión y mezcla, y Hernán la síntesis y efectos. Juntos crearon una música de alta calidad y sonido excepcional."}
        video={"https://www.youtube.com/embed/SwsgDP5r6nA"}
        images={images_purga}
       />
        <Seccion_Masonry
        id={"fausto"}
        title={"Fausto - Teatro Argentino"}
        phg1={"TEMPORADA LIRICA  TEATRO ARGENTINO DE LA PLATA OPERA FAUSTO De Charles Gounod Dirección musical: Diego Masón. Dirección de escena: Paul-Emile Fourny. Escenografía y vestuario: Graciela Galán. Iluminación: Patrick Méeüs"}
        phg2={''}
        images={images_fausto}
       
        />

      <Seccion_Masonry
        id={"inevitable_encuentro"}
        title={"Inevitable Encuentro"}
        phg1={"Una dicotomía fundamental del ser, 'el pensamiento y el deseo'. Estos hechos cuerpos se enfrentan, se oponen, se reconocen, se resisten, y se concilian en un solo acto."}
        phg2={"Interpretes: María Estévez y Cynthia Payne. Dirección: Santiago Culiacciati. Composición musical: Altojardin prod."}
        images={images_inevitableEncuentro}
        
        />
         <Seccion_Masonry
         id={"mulleres"}
         title={"Mulleres"}
         phg1={"MULLERES EN MADRETIERRA Dirección Paula Robres Brenda Rodriguez."}
         images={images_mulleres}
       />

        <Seccion_Masonry
         id={"tacec"}
         title={"TACEC - Teatro Argentino"}
         phg1={"Demolición-reconstrucción propone recorrer sectores del edificio del Teatro Argentino para señalar determinados espacios que remitan o sugieran un vínculo con el pasaje fronterizo entre lo que es y lo que supo ser”, explicaron desde la organización. “Ni los archivos, ni los testigos, ni las víctimas confluyeron en una investigación que desentrañara el enigma del incendio. El recorrido busca incentivar esta reflexión necesaria para que un Teatro se piense a sí mismo y, en ese devenir, nos pensemos también los demás”, completaron."}
         phg2={"La investigación, montaje y dirección general corresponden a Nelson Mallach; como performers actúan Trinidad Falco, María Estévez, Guido Dalponte, María Ibarlín y Santiago Duarte; como músico en escena y autor del diseño sonoro interviene  Guido Dalponte; el vestuario y asesoramiento espacial es de María Oswald; como asistentes de dirección se desempeñan María Ibarlín y Victoria Mutinellii, el asesoramiento arquitectónico es de Karina Cortina y la edición de video de Victoria Mutinelli."
        }
         images={images_tatec}
        />

        <Seccion_Masonry
         id={"pecadora"}
         title={"Pecadora"}
         phg1={"Pecadora es un drama performático en vínculo con un texto es creado por Jerónimo Búffalo y María Estévez. María Estévez también se desempeña como performer, mientras que José Gómez Vega contribuye como músico-performer. La edición de sonido es realizada por Pff y la fotografía es responsabilidad de Sofía Marcos. Jerónimo Búffalo dirige y pone en escena esta obra teatral."}
         phg2={"Autores: Jerónimo Búffalo, María Estévez Performer: María Estévez Músico-performer: José Gómez Vega Edición de sonido: “Pff”Texto: Claudio Hochman Fotografía: Sofía MarcosDirección y Puesta en escena: Jerónimo Búffalo."}
         images={images_pecadora}
       />

        <Seccion_Masonry
        id={"san_martin"}
        title={"Teatro San Martín"}
        phg1={"Intérpretes: Milagros Muñoz, Victoria Chionetti, Clara Brenen, Malena Agrest, Rosa Fernández, María Estévez y Constanza Agüero (integrante del Ballet Contemporáneo del Teatro San Martín) Asistencia de dirección: Malena Agrest . Coreografía: Juan Camargo."}
        phg2={""}
        images={images_sanMartin}
        />

        <Seccion_ImgSimple
         id={"casa_curutchet"}
         title={"Casa Curutchet"}
         images={images_casaCurutchet}
         img1={"/png/teatro-danza/Casa Curutchet/1.jpg"}
         img2={"/png/teatro-danza/Casa Curutchet/2.jpg"}
         img3={"/png/teatro-danza/Casa Curutchet/3.jpeg"}
        />

       <Seccion_SoloImg
          id={"la_inquietud"}
         title={"La inquietud"}
         phg1={"Autoras Cinthia Payne María Estévez. Obra de poética acrobática. Diseño Elisa Ferreira López."}
         img1={"/png/teatro-danza/La inquietud/1.jpg"}
       />
      
      </div>
      <Footer/>
    </div>
  )
}
