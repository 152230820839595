import React from 'react'

const Incoherencia = ({id, title, phg}) => {
  return (
    <div className='flex flex-col gap-4 items-center' id={id}>
        <div className='flex flex-row w-full items-center gap-10'>
            <hr className='w-1/2' />
            <img src="/svg/comillas.svg" alt="Comillas" />
            <hr className='w-1/2' />
        </div>
        <h4>{title}</h4>
        <p className=' text-transparent  bg-clip-text bg-gradient-to-b from-secondary-500/100 to-secondary-500/0'>{phg}</p>
    </div>
  )
}

export default Incoherencia