import React from 'react'
import Portada from '../components/portada'

import Portada_Interna from '../components/portada_interna'
import Navbar from '../NavBar'
import Footer from '../components/footer'
import ButtonUp from '../components/buttonUp'
import Gallery from '../components/gallery'
import Incoherencia from '../components/incoherencia'

export default function Escritos() {
  const incoherenciaList = [
    {
    id:'uno',
    title:'Día 1 Peces de fuego ',
    phg:'Quedar en blanco y comenzar. El tiempo espanta, la quietud atrae. Encandilada y con miedo anuncio el siguiente paso.  Camino al túnel de peces muertos, peces sin espinas, blandos, mal olientes. De ahí se sale primero por la cabeza, mirando afuera. Luego, como cada uno quiera o pueda. Quedarme también puede ser mi aventura. Recorrer los detalles del túnel. Peces encimados. Enzimas bariátricas para poder comerlos todos y morir indigestada. Peces que mueren incendiados en un mar sin agua. En un mar de fuego. ¿Quiénes viven en los mares de fuego?'
    },
    {
    id:'dos',
    title:'Día 2 El caldero del tiempo',
    phg:'No sé porque?. Ser, saber, sentir. Sentir encerrada sin agujeros. Sentir para adentro. Saber para que. Contar hasta doce y no saltar. Jugar en el espacio aunque sea pequeño. Alguien mira desde abajo, pero no  puede entrar. Alguien mira desde arriba, pero no puede saltar. Y yo miro desde adentro. No me gusta. Pongo punto. Silenciosamente guardo los líquidos mágicos que una vez soñé. Encerrados y exhibidos  en una tienda. Tiendita de saldos. En noches de tormentas, tormentas creativas que despiertan monstruos. '
    },
    {
    id:'tres',
    title:'Día 3 ANTIDOTO PARA EL TERCER DIA',
    phg:'Antídoto para el tercer día. Para no resucitar entre los muertos. Para salir corriendo, entrar en un taxi. Taxi amarillo que interrumpe la infancia. Taxi amarillo que te traerá de vuelta. Subir con una mochila repleta de fórmulas. ¿Secretas? ¿Manifiestas?  ¿Útiles? ¿Basura? Sentirte bifurcada, andar derecho. Hacer lo que busques adentro. Sentirte poderosa. Tener el antídoto. Flechas venenosas que te cortan y te achican. '
    },
    {
    id:'cuatro',
    title:'Día 4 Mi línea de flotación ',
    phg:'La cerca de sol está en la punta de la pradera. Tu cuerpo poderoso te lleva con grandes saltos, inesperados saltos que te sorprenden. Sin darte cuenta te encontrás realizando hazañas acrobáticas que te llevan a un lugar solitario, pasando los bosques, pasando las cascadas, pasando los  lobos asesinos, y  las miradas fulminantes. Una vez ahí no queda más que recostarse en la hierba verde y húmeda bajo la cerca de sol que revelará la verdad de tu piel brillante. Sera la mirada abierta y valiente la que te hará gustarte. Si! Si!  Si, gustar de vos. Gustar de tu monstruo. No es una acción solitaria. Es una acción de revote.'
    },
    {
    id:'cinco',
    title:'Día 5 LUNA INVERTIDA',
    phg:'(Primero nombrada y luego creada)  Nombrada en hoja en blanco. Nacida con título.  Luna invertida. Luna de nadie que vea calzado. Ojos calzados con cuero marrón mojados por la lluvia.  Volvemos a empezar, pero desde otro lado. Invierto el pensar, el arriba llega abajo. Luna invertida. Luna amada. Nombrada, nacida sin cuerpo. Título en blanco. Luna inventada pero firme. Los hechos nos sorprenden. '
    },
    {
    id:'seis',
    title:'Día 6 SINUOSA',
    phg:'Escenografía de  un amor.    Camino sinuosa escapando a los vértices. Paredes de concreto que ofrecen libertad. Contrapisos, contrapasos. Pasos en contra de las riendas. Dejar de esconderme detrás de incoherencias, nombrar las palabras sin excusas, hacerlas propias y de nadie.  Las venas abiertas del tiempo que derraman verdades. Las venas cerradas de los miedos que explotan frustraciones. Temer y aun sostener lo que la gravedad ya desintegró. Que mi incoherencia se transforme en relato, en historia, que acontezca.'
    },
    {
    id:'siete',
    title:'Día 7 Madriguera',
    phg:'Madriguera agreste, confusa, pero mía.  Honda y silente. De barro al comienzo y etérea luego. Quieta por fuera, remolino vital por dentro. Ver lo que gira y sacar la sortija. Tal vez una llave…. Seguro una llave.  Cerrojos abiertos. Lagrimas cayendo, sentir la belleza de lo desconocido de lo no atrapado. Madriguera infinita. Madriguera transporte. Volar cayendo. Pensar saliendo. Pausa preciosa que me permite entender. Y otra vez saberme vulnerable. ¿Cuántas madrigueras hay en un bosque? ¿Es necesario un bosque?  No creo…. Madriguera de pensamientos. '
    },
    {
    id:'ocho',
    title:'Día 8 La Replica',
    phg:'Arrancar para no mostrar. Desojar y dejar la huella. El camino de la creación tiene certezas blandas, que se desmoronan, cambian de forma. ¿De donde agarrarse entonces...? ¿o no agarrarse y resistir?  Andar sin suelo. Romper para que no exista, para empezar de nuevo. Una vez leí que la muerte nunca tiene intensidad cero, algo late siempre. ¿Cuál es el color de nuestros deseos más profundos? ¿Todo va de acuerdo al plan?. No hay plan. No? No, ninguno'
    },
    {
      id:'nueve',
      title:'Día 9 Aire Seco',
      phg:'Perdida, clausurada, encerrada sin salida, dormida. Gritar lo mismo sin sonido. Lagrimas. ¿Es válido preguntarse para avanzar? La eterna búsqueda de sentido.¿Donde está la chispa de la alegría? Pensamientos secretos, solo míos. Pensamientos encerrados. '
      },
  ]

  const linskEscritos = [
    {
      name:"uno", 
      href:"#uno"
      },
    
      {
      name:"dos", 
      href:"#dos"
      },
      {
      name:"tres ", 
      href:"#tres"
      },
    
      {
      name:"cuatro", 
      href:"#cuatro"
      },
      {
      name:"cinco", 
      href:"#cinco"
      }, 
      {
      name:"seis", 
      href:"#seis"
      }, 

      {
      name:"siete", 
      href:"#siete"
      }, 
      {
      name:"ocho", 
      href:"#ocho"
      }, 
      {
        name:"nueve", 
        href:"#nueve"
        },  

  ]

  return (  
    <div className='flex flex-col w-full h-full '>
       <div className=" z-10 ">
         <Navbar />
       </div>
        <Portada_Interna
        links={linskEscritos}
        title={"Escritos"}
        page={"escritos"}
        img={"/png/portada-escritos.jpg"}
        />

    <ButtonUp/>
      <div className='flex flex-col bg-primary-500 text-secondary-500 gap-32  px-8 lg:px-28 pt-10  pb-20'>
      {incoherenciaList.map((incoherencia)=>{
        return(
        <Incoherencia id={incoherencia.id} title={incoherencia.title} phg={incoherencia.phg}/>
        )
      })}

      </div>
      <Footer/>
    </div>
  )
}
