import ReactDOM from "react-dom";
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Teatro from "./pages/Teatro";
import Acrobacia from "./pages/Acrobacia";
import './index.css'
import Fotografias from "./pages/Fotografias";
import Audiovisuales from "./pages/Audiovisuales";
import Escritos from "./pages/Escritos";

export default function App(){
return(
<BrowserRouter>
<Routes>
<Route path="/" element={<Layout isHome={true}/>}>
<Route index element={<Home/>}/>
<Route path="teatro" element={<Teatro/>}/>
<Route path="acrobacia" element={<Acrobacia/>}/>
<Route path="fotografias" element={<Fotografias/>}/>
<Route path="audiovisuales" element={<Audiovisuales/>}/>
<Route path="escritos" element={<Escritos/>}/>
</Route>
</Routes>
</BrowserRouter>
);
}

ReactDOM.render(<App />,document.getElementById("root"));