import React, { useEffect, useState } from 'react';
import Portada from '../components/portada'

const useHeightBasedImage = () => {
    const [imageUrl, setImageUrl] = useState('');
  
    useEffect(() => {
      const handleResize = () => {
        const { innerHeight } = window;
        
        if (innerHeight < 800) {
          setImageUrl('/png/portada-home.jpg');
        } else {
          setImageUrl('/png/portada-landing.jpg');
        }
      };
  
      handleResize(); // Se ejecuta al cargar la página
  
      window.addEventListener('resize', handleResize); // Se suscribe al evento de cambio de tamaño de ventana
  
      return () => {
        window.removeEventListener('resize', handleResize); // Se limpia el evento al desmontar el componente
      };
    }, []);
  
    return imageUrl;
  };


function Home()  {
    const linksHome = [
        {
        name:"Teatro Danza", 
        href:"/teatro"
        },
        {
        name:"Acrobacia", 
        href:"/acrobacia"
        },
        {
        name:"Audiovisuales", 
        href:"/audiovisuales"
        },
        {
        name:"Prod. Fotográficas", 
        href:"/fotografias"
        },
        {
        name:"Escritos", 
        href:"/escritos"
        }
    ]
    const imageUrl = useHeightBasedImage();

    return (
        
    <div className='w-screen h-screen '>
            <Portada
                links={linksHome}
                img={imageUrl}
                imgMobile={"/png/portada-home.jpg"}
                title={"María Estevez"}/>
            </div>
    );
    }

export default Home;