import React from 'react'
import Portada from '../components/portada'


import Portada_Interna from '../components/portada_interna'
import Navbar from '../NavBar'
import Footer from '../components/footer'
import ButtonUp from '../components/buttonUp'
import Gallery from '../components/gallery'
import GalleryVideo from '../components/gallery-video'

export default function Acrobacia() {
  const imgList = [
    "/png/acrobacia/1.jpg",
    "/png/acrobacia/2.jpg",
    "/png/acrobacia/3.jpg",
    "/png/acrobacia/4.jpg",
    "/png/acrobacia/5.jpg",
    "/png/acrobacia/6.jpg",
    "/png/acrobacia/7.jpg",
    "/png/acrobacia/8.jpg",
    "/png/acrobacia/9.jpg",
    "/png/acrobacia/10.jpg",
    "/png/acrobacia/11.jpg",
    "/png/acrobacia/12.jpg",
    "/png/acrobacia/13.jpg",
    "/png/acrobacia/14.jpg",
    "/png/acrobacia/15.jpg",
    "/png/acrobacia/16.jpg",
    "/png/acrobacia/17.jpg",

  
  ]

  const videoList = [
    "/png/acrobacia/acrobacia_1.mov",
    "/png/acrobacia/acrobacia_2.mov",
    "/png/acrobacia/acrobacia_3.mov",

  ]
const linksNull=[

]
  return (  
    <div className='flex flex-col w-full h-full '>
       <div className=" z-10 ">
         <Navbar />
       </div>
        <Portada_Interna
        links={linksNull}
        title={"Acrobacia"}
        img={"/png/portada-acrobacia.jpg"}
        />

    <ButtonUp/>
      <div className='flex flex-col bg-primary-500 text-secondary-500 gap-32  px-8 lg:px-28 pt-10  pb-20'>
      <GalleryVideo
        images={imgList}
        videos={videoList}
      />

      </div>
      <Footer/>
    </div>
  )
}
