import React from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

const Seccion_ImgSimple = ({id, title, images, imgAlt}) => {
  return (
    <div id={id} className='flex flex-col h-full w-full gap-12 py-10'>
        <h3>{title}</h3>
        <ResponsiveMasonry 
                columnsCountBreakPoints={{350: 1, 750: 2, 1280: 2}}
            >
                <Masonry gutter="20px">
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={imgAlt+"_index"} 
                  />
                ))}
                </Masonry>
            </ResponsiveMasonry>
    </div>
  )
}

export default Seccion_ImgSimple