import React, { useEffect, useState } from 'react'
import Portada from '../components/portada'

import Seccion_Video from '../components/seccion_video'

import Seccion_SoloImg from '../components/seccion_soloimg'
import Seccion_ImgSimple from '../components/seccion_imgsimple'
import Seccion_Masonry from '../components/seccion_masonry'
import Portada_Interna from '../components/portada_interna'
import Navbar from '../NavBar'
import Footer from '../components/footer'
import ButtonUp from '../components/buttonUp'
import Seccion_ImgDoble from '../components/seccion_imgdoble'
import Seccion_MasonryVideo from '../components/seccion_masonry_video'
import Seccion_MasonryVideoVertical from '../components/seccion_masonry_video_vertical'

export default function Audiovisuales() {
  const linksAudiovisuales = [
    {
    name:"Cine Expandido", 
    href:"#cine_expandido"
    },

    {
    name:"Ejecicios Nocturnos", 
    href:"#ejercicios_nocturnos"
    },
    {
    name:"Purga ", 
    href:"#purga"
    },

    {
    name:"Selva", 
    href:"#selva"
    },
    {
    name:"The Edge of the Void ", 
    href:"#the_edge_of_the_void"
    },
    {
    name:"Jean Genet Ahora", 
    href:"#jean_genet_ahora"
    },

    {
    name:"Lennons", 
    href:"#lennons"
    },
    {
    name:"Ofrenda", 
    href:"#ofrenda"
    },
    {
    name:"Ex", 
    href:"#ex"
    },

    {
    name:"DOUTEZ EMMA SIAUD", 
    href:"#doutez_emma_siaud"
    },
]

const images_cineExpandido = [
 "/png/audiovisuales/CINE EXPANDIDO/2.jpg",
  "/png/audiovisuales/CINE EXPANDIDO/3.jpg",
  "/png/audiovisuales/CINE EXPANDIDO/1.jpg",
  "/png/audiovisuales/CINE EXPANDIDO/4.jpg",
  
]

const images_theEdge = [
"/png/audiovisuales/The Edge of the Void/3.jpg",
"/png/audiovisuales/The Edge of the Void/4.jpg",
"/png/audiovisuales/The Edge of the Void/1.jpg",
"/png/audiovisuales/The Edge of the Void/2.jpg",
"/png/audiovisuales/The Edge of the Void/5.jpg",
"/png/audiovisuales/The Edge of the Void/6.jpg",
"/png/audiovisuales/The Edge of the Void/7.jpg",
"/png/audiovisuales/The Edge of the Void/8.jpg"
]
const images_purga = [
  "/png/teatro-danza/purga/2.jpg"
]
const images_ejercicios = [
 "/png/audiovisuales/Ejercicios Nocturnos/2.jpg",
 "/png/audiovisuales/Ejercicios Nocturnos/3.jpg",
  "/png/audiovisuales/Ejercicios Nocturnos/1.jpg",
  "/png/audiovisuales/Ejercicios Nocturnos/4.jpg"
]

const images_selva = [
  "/png/audiovisuales/Selva/2.jpg",
 "/png/audiovisuales/Selva/1.jpg",
 "/png/audiovisuales/Selva/3.jpg",
 "/png/audiovisuales/Selva/4.jpg",
]

const images_jeanGenet = [
  "/png/audiovisuales/Jean Genet/2.jpg",
  "/png/audiovisuales/Jean Genet/1.jpg",
  "/png/audiovisuales/Jean Genet/3.jpg",
  "/png/audiovisuales/Jean Genet/4.jpg",
]
const images_lennons = [
  "/png/audiovisuales/Lennons/1.jpg",
  "/png/audiovisuales/Lennons/2.jpg",
  "/png/audiovisuales/Lennons/3.jpg",

]
const images_ofrenda = [
  "/png/audiovisuales/Ofrenda/1.jpg",
  "/png/audiovisuales/Ofrenda/2.jpg",
  "/png/audiovisuales/Ofrenda/3.png",

]

const images_ex = [
  "/png/audiovisuales/EX/1.jpg",
  "/png/audiovisuales/EX/2.jpg",
  "/png/audiovisuales/EX/3.jpg",
]
const images_doutez = [
  "/png/audiovisuales/DOUTEZ EMMA SIAUD/1.jpg",
  "/png/audiovisuales/DOUTEZ EMMA SIAUD/2.jpg",
]
const useHeightBasedImage = () => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const handleResize = () => {
      const { innerHeight, innerWidth } = window;
      
      if (innerHeight < 600) {
        setImageUrl("/png/portada-audiovisuales-1.jpg");
      } else if(innerWidth<600){
        setImageUrl("/png/portada-audiovisuales-1.jpg");
      } else if(innerWidth>600){
        setImageUrl("/png/portada-audiovisuales.jpg");

      }else {
        setImageUrl("/png/portada-audiovisuales.jpg");
      }
    };

    handleResize(); // Se ejecuta al cargar la página

    window.addEventListener('resize', handleResize); // Se suscribe al evento de cambio de tamaño de ventana

    return () => {
      window.removeEventListener('resize', handleResize); // Se limpia el evento al desmontar el componente
    };
  }, []);

  return imageUrl;
};
const imageUrl = useHeightBasedImage();

  return (  

    <div className='flex flex-col w-full h-full '>
      <div className=" z-10 ">
         <Navbar />
       </div>
      <Portada_Interna
      links={linksAudiovisuales}
      title={"Audiovisuales"}
      page={"audiovisuales"}
      img={imageUrl}
      />

     <ButtonUp/>
      <div className='flex flex-col bg-primary-500 text-secondary-500 gap-32  px-8 lg:px-28 pt-10 '>
        <Seccion_MasonryVideoVertical
        id={"cine_expandido"}
        title={"Cine Expandido"}
        phg1={"Surge  del cruce de miradas interdisciplinarias , el autor del texto, los directores y la performer , resultando una pieza en la que cada uno de los lenguajes artísticos crean capas superpuestas y niveles de lectura generando en el espectador preguntas, y competencias visuales: ¿que miro,la pantalla o la persona?¿soy indiferente a las personas por las imágenes?¿que pasa en mi al ver, soy María? Son tan solo algunas posibles. En el nombre María encontramos a todas las mujeres. Su diálogo interno, la lucha, las contradicciones, los tabúes, las antiguas estructuras, las angustias y  los pesares que las habitan. Esta obra plantea la deconstrucción como una toma de posición con respecto del estructuralismo."}
     
        phg2={
          "Dirección y Producción: MARINA VILLANUEVA & MARCOS PEUSCOVICH.  Sobre un texto del libro “Mulheres nascidas de um nome” de CLAUDIO HOCHMAN         Performer: MARIA ESTEVEZ. Diseño coreográfico:MARIA ESTEVEZ y  JERONIMO BUFALLO. Montaje: PAULA HEID. Sonido y voz: FRANCO ANDRES BARSI"
        }
        images={images_cineExpandido}
        video={"/png/audiovisuales/CINE EXPANDIDO/cine_Expandido.mp4"}
       
        />
        <Seccion_Masonry
        id={"ejercicios_nocturnos"}
        title={"Ejercicios Nocturnos"}
        images={images_ejercicios}
        phg1={"Proyecto audiovisual Premio Mecenazgo. Siete textos interpretados por María Estévez, una puesta de escena  que apuesta al Cuerpo como creador de sentido y comunicación, que apela a otros sentidos de los que estamos acostumbrados a usar todos los días. Una mirada hacia lo que no se ve, lo que no se dice, lo que está escondido.el ritmo, la atmósfera, la gravedad es  marcada por el decir de los textos o a lo sumo algunos sonidos ambiente."}
        phg2={"Director Sergio Rentero. Textos Sergio Rentero. Productora Gotika. Rol protagónico en Seis Microcortos."}
       
       />
        <Seccion_Video
        id={"purga"}
        title={"Purga"}
        phg1={"Purga es una producción audiovisual  del Centro de Arte de la UNLP que surgió a partir de la puesta en escena de un trabajo creado por María Estevez, en el espacio creativo Tête à Tête que dirige Claudio Hochman. "}
        phg2={"Tres artistas participaron en la ejecución musical: Fernanda tocó los violines, Martin la percusión y mezcla, y Hernán la síntesis y efectos. Juntos crearon una música de alta calidad y sonido excepcional."}
        images={images_purga}
        video={"https://www.youtube.com/embed/SwsgDP5r6nA"}
       />
       <Seccion_Masonry
         id={"selva"}
         title={"Selva"}
         phg1={"SELVA by Motonets Video Clip para Daniel Melingo. Directora Constanza Lagreca. Vestuario Panni Margot"}
        images={images_selva}
        />

        <Seccion_MasonryVideo
         id={"the_edge_of_the_void"}
         title={"The Edge of the Void"}
         phg1={"Este vídeo de danza y performance es el resultado de un proceso creativo dirigido por Joaquín Mora en colaboración con Ocho al Cubo. Este proceso, donde la imponente presencia de la actriz argentina María Estévez, la composición sonora de Sebastián Vergara, la dirección de arte de Florencia Astorga y la cinematografía de Cristóbal Garrido, se combinan en un proceso intenso y expresivo. The Edge of the Void se realizó en diciembre de 2021, como una expresión catártica, después de la pandemia que nos golpeó.         "}
         phg2={"La geografía de Ochoquebradas nace del choque de dos poderosas fuerzas: la Cordillera de los Andes y el Océano Pacífico. Allí, la sinuosa obra de Nishisiwa emula el movimiento de las placas convergentes y se funde con el entorno en una vibración silenciosa. La arquitectura actúa como un catalizador para el pulso del magma, como un portal que conecta con la energía oculta de la tierra. Al entrar, experimentamos esa tensión: la mirada no resuelta hacia lo inasible. En The Edge of the Void las fuerzas de la naturaleza dan forma al espacio como vientos que nos liberan de todos los centros. Allí, nuestro cuerpo se transforma, deja de elegir y se entrega a lo incontrolable, al mismo sentimiento que da vida a la obra de Nishisiwa."}
        images={images_theEdge}
        video={"/png/audiovisuales/The Edge of the Void/the_edge.mp4"}
        />
        <Seccion_Masonry
         id={"jean_genet_ahora"}
         title={"Jean Genet Ahora"}
         phg1={"Documental - ficción que transcurre entre Francia y Argentina y que toma como punto de partida los textos políticos escritos por Jean Genet en los años 70.         "}
         phg2={"Director Guionista Miguel Zeballos. Rol Actriz -Bailarina        "}
        images={images_jeanGenet}
        />

      <Seccion_Masonry
         id={"lennons"}
         title={"Lennons"}
         phg1={"Película del Director José Cicala  filamada en enero y febrero de 2021  a estrenarse en el Junio 2023. "}
         phg2={"Participación  Rol/ Personaje  Angelica.       "}
        images={images_lennons}
        />

    <Seccion_Masonry
         id={"ofrenda"}
         title={"Ofrenda"}
         phg1={"Es una película conformada por 43 Cortos de Mujeres Directoras que se entrenó en el Centro Cultura Kirchner en el marco del cierre de 35 Festival de la Mujer y el Cine, Mayo 2023.         "}
         phg2={"Por la convocatoria de la Directora de Cine Sandra Gugliotta  tuve el honor de protagonizar uno de los Cortos que integran el largometraje.       "}
        images={images_ofrenda}
        />

      <Seccion_Masonry
         id={"ex"}
         title={"Ex"}
         phg1={"EX video Clip Los Tipitos. Dirigido por Gonzalo López. Producción ejecutiva: Gloria López. Diseño de Coreografía & Coach: Paola García. Dirección de Fotografía y Cámara: Leonardo Druventi.         "}
        images={images_ex}
        />

    <Seccion_Masonry
         id={"doutez_emma_siaud"}
         title={"DOUTEZ EMMA SIAUD"}
         phg1={"Video Clip Artista Francesa Emma Siaud. Directores Matías HLACE & Ezequiel CAMBIASO. Producción: Soundshape Production & Emma SIAUD. Coreógrafas: Ailín BARS & Victoria CHIONETTI. Rol: Bailarina.         "}
        images={images_doutez}
        />
      </div>
      <Footer/>
    </div>
  )
}
