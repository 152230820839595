import React from 'react'
import ReactPlayer from 'react-player'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

const Seccion_MasonryVideoVertical = ({id, title, images, phg1, phg2,video,imgAlt}) => {
  return (
    <div id={id} className='flex flex-col h-full w-full gap-12 py-10'>
        <h3>{title}</h3>
        <ResponsiveMasonry 
                columnsCountBreakPoints={{350: 1, 750: 1, 1024:2, 1280: 2}}
            >
              
                <Masonry gutter="20px">
                <p>{phg1}</p>
              
                <div className='relative w-full h-full bg-black'>
                    <ReactPlayer  className='relative aspect-video  w-full h-full ' width='100%' height='100%'
                      controls url={video}/>
                 </div>
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={"Maria_Estevez_"+title+"_"+index} 
                  />
                ))}
                
                 <p>{phg2}</p>
                </Masonry>
            </ResponsiveMasonry>

    </div>
  )
}

export default Seccion_MasonryVideoVertical